.link{
letter-spacing: 0.0001em;
font-family: "Open Sans", sans-serif;
font-weight: 300;
/* font-size: 1rem; */
line-height: 1.5;
text-decoration: underline 1.5px dotted rgb(189, 30, 81);
text-underline-offset: 4px;
cursor: pointer;
}
.fl{
float: right;
}
